<template>
  <div>
    <div class="video" id="player"/>
    <div v-if="!videoSuccess" class="error-tip">
      <p class="s1">暂</p>
      <p class="s2">无</p>
      <p class="s3">视</p>
      <p class="s4">频</p>
      <p class="s5">.</p>
      <p class="s6">.</p>
      <p class="s7">.</p>
      <p class="s8">.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "H5Player",
  data() {
    return {
      player: null,
      muted: true,
      url: "",
      videoSuccess: true,
      //重试次数
      repeat: 0,
      isPlaying: false,
      inRepeat: false
    }
  },
  mounted() {
    this.init()
    this.createPlayer()
  },
  methods: {
    init() {
      // 设置播放容器的宽高并监听窗口大小变化
      window.addEventListener('resize', () => {
        this.player.JS_Resize()
      })
    },
    createPlayer() {
      let that = this
      this.player = new window.JSPlugin({
        szId: 'player',
        szBasePath: "/",
        iMaxSplit: 1,
        iCurrentSplit: 1,
        openDebug: true,
        oStyle: {
          borderSelect: '#000',
        }
      })

      // 事件回调绑定
      this.player.JS_SetWindowControlCallback({
        windowEventSelect: function (iWndIndex) {  //插件选中窗口回调
          console.log('windowSelect callback: ', iWndIndex);
        },
        pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {  //插件错误回调
          console.log('pluginError callback: ', iWndIndex, iErrorCode, oError);
          switch (iErrorCode) {
            case "0x12f910000":
            case "0x12f900009":
            case "0x12f910010":
            case "0x12f910011":
              that.rePlay()
              break;
            default:
              that.videoSuccess = false;
              break;
          }
        },
        windowEventOver: function (iWndIndex) {  //鼠标移过回调
          //console.log(iWndIndex);
        },
        windowEventOut: function (iWndIndex) {  //鼠标移出回调
          //console.log(iWndIndex);
        },
        windowEventUp: function (iWndIndex) {  //鼠标mouseup事件回调
          //console.log(iWndIndex);
        },
        windowFullCcreenChange: function (bFull) {  //全屏切换回调
          console.log('fullScreen callback: ', bFull);
        },
        firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {  //首帧显示回调
          console.log('firstFrame loaded callback: ', iWndIndex, iWidth, iHeight);
        },
        performanceLack: function () {  //性能不足回调
          console.log('performanceLack callback: ');
        }
      });
    },

    rePlay() {
      this.repeat++;
      if (this.repeat <= 3) {
        setTimeout(() => {
          console.log("重试", this.repeat, this.url)
          this.realPlay(this.url)
        }, 1000)
      } else {
        this.repeat = 0;
        this.videoSuccess = false;
      }
    },

    playVideo(playUrl) {
      if (!playUrl) {
        this.videoSuccess = false;
        return;
      }
      this.videoSuccess = true
      this.repeat = 0;
      this.url = playUrl;
      this.realPlay(this.url);
    },

    /* 预览&对讲 */
    async realPlay(playURL) {
      let {player} = this
      if (player) {
        await this.stopPlay();
        let index = player.currentWindowIndex
        try {
          await player.JS_Play(playURL, {playURL, mode: 1}, index)
          this.isPlaying = true
          this.repeat = 0
          console.log('realplay success')
        } catch (e) {
          console.error(e)
        }
      }
    },
    async stopPlay() {
      if (!this.isPlaying) return
      try {
        await this.player.JS_Stop()
        this.isPlaying = false
        console.log('stop realplay success')
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>

<style scoped>
.video {
  width: 100%;
  height: 100%;
}

.error-tip {
  width: 300px;
  height: 50px;
  color: #fff;
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.error-tip p {
  /* float: left; */
  font-size: 20px;
  padding-right: 16px;
  animation-name: myani;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: alternate;
  animation-fill-mode: both;
}

s1 {
  animation-delay: 0.2s;
}

.s2 {
  animation-delay: 0.4s;
}

.s3 {
  animation-delay: 0.6s;
}

.s4 {
  animation-delay: 0.8s;
}

.s5 {
  animation-delay: 1s;
}

.s6 {
  animation-delay: 1.2s;
}

.s7 {
  animation-delay: 1.4s;
}

.s8 {
  animation-delay: 1.6s;
}

@keyframes myani {
  0%, 100% {
    padding-bottom: 0;
  }
  50% {
    transform: translateY(-20px);
  }
}
</style>
