<template>
  <dv-full-screen-container class="bg">
    <Header />
    <Footer />
    <div class="box">
      <div class="left">
        <h5>监控列表</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
        >
        <div class="listBox">
          <div>{{ title }}</div>
          <div
            v-for="(item, ul) in listUlData.child"
            :key="ul"
            class="listUl"
          >
            <h6 @click="handleA(ul)">
              {{ item.name }}
              <img
                v-if="item.aShow"
                src="@/assets/home/zhankai.png"
                alt=""
              >
              <img
                v-if="!item.aShow"
                src="@/assets/home/zhankai1.png"
                alt=""
              >
            </h6>
            <ul v-if="item.aShow&&item.cameraList">
              <li
                v-for="(it, ind) in item.cameraList"
                :key="it.regionIndexCode"
                :class="it.cameraIndexCode === activeA ? 'activeA' : ''"
                @click="handleChildRegion(it, ind)"
              >
                {{ it.cameraName }}
              </li>
            </ul>
            <ul v-else-if="item.aShow&&item.kidRegion">
              <div
                v-for="(it2, ind2) in item.kidRegion"
                :key="it2.indexCode"
                class="select-item"
                :class="it2.indexCode === activeA ? 'activeA' : ''"
                @click="handleChildRegion(it2, ind2, true)"
              >
                <Select
                  v-model="it2.name"
                  :list="it2.cameraList"
                  :default-name="it2.name"
                  :index-code="it2.indexCode"
                  @change="handleChange"
                />
              </div>
            </ul>
            <div
              v-else-if="item.aShow"
              style="font-size:18px;padding:0 20px"
            >
              暂无监控
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="rightShow"
        class="right"
      >
        <h5-player
          ref="player"
          v-loading="videoLoading"
          class="right-ccenter-z"
        />
        <!--        <div class="right-ccenter-z" id="player_1" v-loading="video_loading" v-if="video_success"></div>-->
        <!--        <div v-else class="error-tip">-->
        <!--			  	<p class="s1">暂</p>-->
        <!--			  	<p class="s2">无</p>-->
        <!--			  	<p class="s3">视</p>-->
        <!--			  	<p class="s4">频</p>-->
        <!--			  	<p class="s5">.</p>-->
        <!--			  	<p class="s6">.</p>-->
        <!--			  	<p class="s7">.</p>-->
        <!--			  	<p class="s8">.</p>-->
        <!--			  </div>-->
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import Select from "@/components/Select/index5.vue";
import H5Player from "_c/H5Player";
import videoUrlMixin from "@/mixins/videoUrlMixin";

export default {
  components: {
    Header,
    Footer,
    Select,
    H5Player
  },
  mixins: [videoUrlMixin],
  data() {
    return {
      title: '',
      listUlData: [],
      rightShow: true,
      // activeA: null,
      activeA: 0,
    };
  },
  mounted() {
    this.getPlantArea();
  },
  methods: {
    handleA(item) {
      console.log(this.listUlData.child[item]);
      this.$set(this.listUlData.child[item], "aShow", !this.listUlData.child[item].aShow);
      this.$forceUpdate();
    },
    handleChildRegion(data, ind, flag = false) {
      if (this.videoLoading || flag) {
        return;
      }
      this.activeA = data.cameraIndexCode;
      this.getPreviewStream();
    },
    async getPlantArea() {
      let res = await this.$api.device.getFactoryCamera();
      console.log(res);
      if (res.code === 200) {
        this.listUlData = res.data;
        this.listUlData.child.map((item) => {
          item.aShow = false;
        });
        this.listUlData.child[0].aShow = true;
        if (this.listUlData.child[0].child) {
          this.activeA = this.listUlData.child[0].kidRegion[0].cameraList[0].cameraIndexCode;
          await this.getPreviewStream();
        } else if (this.listUlData.child[0].cameraList) {
          this.activeA = this.listUlData.child[0].cameraList[0].cameraIndexCode;
          await this.getPreviewStream();
        } else {
          this.activeA = -1;
          this.$refs.player.videoSuccess = false;
        }
      }
    },
    //获取预览流
    async getPreviewStream() {
      const url = await this.getVideoUrl(this.activeA);
      this.$refs.player.playVideo(url);

      // let that = this;
      // const url = "/api/getCameraPreviewURL?cameraIndexCode=" + this.activeA;
      // if(that.players2){
      //   that.players2.JS_Stop(that.players2.currentWindowIndex).then(
      //     () => {
      //     console.info('JS_Stop success');
      //     // do you want...
      //     },
      //     (err) => {
      //     console.info('JS_Stop failed:', err);
      //     // do you want...
      //     }
      //   );
      //
      //   that.players2 = null;
      //   document.getElementById('player_1').innerHTML = ""
      // }
      // try {
      // axios({
      //   url:
      //     process.env.NODE_ENV === "production"
      //       ? `http://127.0.0.1:1000${url}`
      //       : `/api${url}`,
      //   method: "post",
      //   withCredentials: false,
      // }).then((res) => {
      //   console.log(res);
      //   if(!res.data.data){
      //     that.video_loading = false;
      //     that.video_success = false;
      //     return
      //   }
      //   that.players2 = new window.JSPlugin({
      //     szId: "player_1",
      //     szBasePath: "/",
      //     iMaxSplit: 1,
      //     iCurrentSplit: 1,
      //     openDebug: true,
      //     oStyle: {
      //       borderSelect: "#000",
      //     },
      //   });
      //   let index = that.players2.currentWindowIndex,
      //     playURL = res.data.data.url,
      //     mode = 1;
      //   that.video_loading = false;
      //   that.players2.JS_Play(playURL, { playURL, mode }, index).then(
      //     () => {
      //       console.log("realplay success");
      //     },
      //     (e) => {
      //       console.error(e);
      //       that.players2 = null;
      //       document.getElementById('player_1').innerHTML = ""
      //       that.video_success = false;
      //     }
      //   );
      // });
      // } catch (error) {
      //   console.log(error)
      //   that.video_success = false;
      // }
    },
    handleChange(e) {
      console.log(e)
      if (this.videoLoading) {
        return;
      }
      this.activeA = e.indexCode;
      this.getPreviewStream();
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("../../assets/video/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.box {
  display: flex;
  position: relative;
  top: 0.8rem;
  left: 0.2rem;
}

.left,
.right {
  color: #fff;
  height: 10rem;
}

.left {
  left: 0.3rem;
  width: 5.4rem;
  background-image: url("../../assets/video/videoBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0.1rem;

  h5 {
    padding-left: 0.55rem;
    padding-top: 0.05rem;
    font-size: 0.3rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #f9fdff;
    line-height: 0.45rem;
  }

  .imgs {
    position: absolute;
    left: 4.95rem;
    top: 0.25rem;
  }

  .listBox {
    height: 9rem;
    margin-top: 0.2rem;
    margin-right: 0.12rem;
    overflow: auto;
  }

  .listUl {
    width: 100%;
    line-height: 0.7rem;
    // margin-bottom: 0.3rem;
    h6 {
      height: 0.7rem;
      background-image: url("../../assets/video/videoT.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      font-weight: 700;
      font-size: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.25rem;

      img {
        width: 0.15rem;
        height: 0.15rem;
      }

      //   padding-left: 0.25rem;
    }

    ul {
      width: 5.1rem;
      padding: 0.25rem 0rem 0.3rem 0.3rem;
      display: flex;
      flex-wrap: wrap;

      li {
        cursor: pointer;
        padding: 0 0.2rem;
        width: calc(50% - 0.1rem);
        height: 0.75rem;
        text-align: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../assets/video/videoK.png");
        font-size: 0.2rem;
        // color: #6793b8;
        letter-spacing: 0.01rem;
      }

      .select-item {
        width: calc(50% - 0.1rem);
        height: 0.75rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../assets/video/videoK.png");
      }

      .activeA {
        background-image: url("../../assets/video/videosK.png");
        font-weight: 700;
        color: #00d8ff;
      }
    }
  }
}

.right {
  //   position: absolute;
  // width: calc(100vw - 4.5rem);
  // right: 0;
  flex: 1;
  margin-left: 0.3rem;
  position: relative;

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      position: relative;
      width: calc(33% - 0.5rem);
      height: 3.29rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../assets/video/videos.png");
      margin-bottom: 0.09rem;
      padding: 0.2rem;

      .title {
        position: absolute;
        top: 0.1rem;
        width: 95%;
        height: 0.35rem;
        margin: 0 0.12rem;
        padding: 0.05rem 0.18rem;
        line-height: 0.35rem;
        background-image: url("../../assets/video/videoTitle.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 999;
      }
    }
  }
}

.rightRotate {
  position: relative;
  width: calc(100vw - 6rem);
  right: 0;
  margin-left: 0.2rem;
  height: 10rem;
  background-image: url("../../assets/video/bgs.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .imgBg {
    position: absolute;
    width: 2.125rem;
    height: 2.125rem;
    background-image: url("../../assets/video/yuan.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    bottom: 0.5rem;
    left: 50%;
    margin-left: -1.0625rem;

    .title {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -0.2885rem;
      margin-top: -0.2885rem;
      width: 0.575rem;
      height: 0.575rem;
      background: #00b1d1;
      box-shadow: 0px 0px 12px 0px rgba(5, 38, 44, 0.7),
      0px 0px 12px 0px #007d94;
      border-radius: 50%;
      line-height: 0.575rem;
      text-align: center;
      color: #fff;
      font-size: 0.18rem;
    }

    .imgTop {
      position: absolute;
      width: 0.9rem;
      left: calc(50% - 0.45rem);
    }

    .imgLeft {
      position: absolute;
      height: 0.9rem;
      left: 0;
      top: calc(50% - 0.45rem);
    }

    .imgRight {
      position: absolute;
      right: 0;
      height: 0.9rem;
      top: calc(50% - 0.45rem);
    }

    .imgBottom {
      position: absolute;
      bottom: 0;
      width: 0.9rem;
      left: calc(50% - 0.45rem);
    }
  }

  .return {
    color: #00d8ff;
    font-size: 0.23rem;
    position: absolute;
    left: 0.3rem;
    top: 0.2rem;
    cursor: pointer;
  }
}

/* 滚动条样式 */
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  //   background-color: #00d8ff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px #08101d;
  // border-radius: 10px;
  background-color: #0b1933;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px #152847;
  background-color: #00d8ff;
}
</style>
<style lang='scss'>
.right-ccenter-z {
  width: 17.8rem;
  height: 10rem;
}

.right-center-video-z {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
<style scoped>
.right >>> .el-loading-mask {
  background-color: #00000000;
}
</style>
