<template>
  <div class="select-box">
    <div
      class="select"
      :class="show ? 'act' : ''"
      @click="handleShow"
      @blur="handleClose"
    >
      <div @click="handleFilter" class="value-box">
        <div>{{inputValue}}</div>
        <i :class="show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" class="arrow"></i>
      </div>
    </div>
    <transition name="el-zoom-in-top">
      <div class="select-options" v-show="show">
        <ul>
          <li
            v-for="(item, idx) in list"
            :key="idx"
            :class="value === item.cameraIndexCode ? 'active' : ''"
            @click="handleSelect(item)"
          >
            <span>{{ item.cameraName }}</span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["value", "list", "defaultName", "indexCode"],
  data() {
    return {
      options: [],
      show: false,
      styleObject: {},
      inputValue: "",
    };
  },
  created() {
    this.inputValue = this.defaultName;
  },
  watch: {
    value(val) {

    },
    list(val) {
      this.options = arr.concat(val);
    },
  },
  mounted() {
    //点击其他区域关闭下拉菜单
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) this.show = false;
    });
  },
  methods: {
    handleFilter() {
      
    },
    handleShow() {
      this.show = !this.show;
    },
    handleClose() {
      this.show = false;
    },
    handleSelect(item) {
      this.show = false;
      this.inputValue = item.cameraName;
      let data = JSON.parse(JSON.stringify(item))
      data.indexCode = this.indexCode;
      this.$emit("input", item.cameraIndexCode);
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  position: relative;
}
.select {
  display: inline-block;
  position: relative;
  font-size: 0.2rem;
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  // background-image: url("../../assets/video/videoK.png");
  top: 0;
  text-align: center;
  padding-right: 0.2rem;
  cursor: pointer;
  padding-left: 0.05rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-top: 0.03rem;
  padding-bottom: 0.03rem;
  width: 100%;
  .value-box {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 100%;
      overflow: hidden;//溢出隐藏
      white-space: nowrap;//禁止换行
      text-overflow: ellipsis;//...
    }
  }
  .arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  > a {
    overflow: hidden;

    position: relative;
    color: #6793b8;
    letter-spacing: 0.01rem;
    text-align: center;
    width: 100%;
    input {
      background: transparent;
      border: none;
      line-height: 0.75rem;
      font-size: 0.2rem;
      text-align: center;
      color: #fff;
    }
    i {
      z-index: 1;
      position: absolute;
      color: #fff;
      right: 0.1rem;
      top: 0.3rem;
    }
  }
  &.act {
    background-image: url("../../assets/video/videosK.png");
  }
}
.select-options {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 100%;
  z-index: 2;
  background-image: url("../../assets/home/xiala.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  z-index: 100;
  padding: 10px 8px 10px 4px;
  ul {
    // max-height: 2.5rem;
    height: 1.4rem;
    border-radius: 0.05rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(23, 43, 2, 0.7);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #00d8ff;
      border-radius: 4px;
    }
    li {
      padding: 0.03rem 0;
      cursor: pointer;
      margin-top: 4px;
      font-size: 0.2rem;
      line-height: 0.5rem;
      text-align: center;
      span {
        white-space: nowrap;
        color: #fff;
      }
      &:hover,
      &.active {
        span {
          color: #00c9e9;
        }
      }
    }
  }
}
/* 滚动条样式 */
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  //   background-color: #00d8ff;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px #08101d;
  // border-radius: 10px;
  background-color: #0b1933;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px #152847;
  background-color: #00d8ff;
}
</style>
