import axios from "axios";

export default {
  data() {
    return {
      videoLoading: false
    }
  },
  methods: {
    async getVideoUrl(cameraIndexCode) {
      const url = (process.env.NODE_ENV === "production" ? `http://127.0.0.1:1000` : `/api`) + "/api/getCameraPreviewURL?cameraIndexCode=" + cameraIndexCode;
      this.videoLoading = true;
      try {
        const res = await axios.post(url);
        if (res.data.data === undefined) {
          return '';
        }
        return res.data.data.url;
      } catch (e) {
        console.log(e);
        return ''
      }finally {
        this.videoLoading = false;
      }
    }
  }
}
